// @flow

/**
 * Module dependencies.
 */

import { Button, Text } from '@slyk/design-system';
import { color, media } from 'react-components/styles';
import { useTranslate } from '@slyk/i18n';
import PhoneNumberInputForm from 'payspace/client/components/home/phone-number-input-form';
import React, { type Node } from 'react';
import qrCode from 'payspace/client/assets/svg/24/qr-code.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  code: ?string,
  openModal: () => void
|};

/**
 * `StyledFormInputNumber` styled component.
 */

const StyledFormInputNumber = styled(PhoneNumberInputForm)`
  ${media.min('lg')`
    max-width: 375px;
  `}
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Text).attrs({ size: 'small' })`
  color: ${color('gray500')};
  display: block;
  margin-bottom: 16px;
`;

/**
 * `InfoWrapper` styled component.
 */

const InfoWrapper = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;
`;

/**
 * `DesktopAction` component.
 */

function DesktopAction({ code, openModal }: Props): Node {
  const { translate } = useTranslate();

  return (
    <>
      <Text
        color={color('secondary')}
        fontWeight={700}
      >
        {translate('header.title')}
      </Text>

      <Lead>
        {translate('header.lead')}
      </Lead>

      <StyledFormInputNumber code={code} />

      <InfoWrapper>
        <Text>
          {translate('header.info.label')}
        </Text>

        <Button
          alignIcon={'right'}
          colorTheme={'gray'}
          icon={qrCode}
          iconSize={'medium'}
          onClick={openModal}
          responsive={false}
          size={'medium'}
        >
          {translate('header.info.qrCode')}
        </Button>
      </InfoWrapper>
    </>
  );
}

/**
 * Export `DesktopAction` component.
 */

export default DesktopAction;
