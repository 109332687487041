// @flow

/**
 * Module dependencies.
 */

import { type ImageSources } from '@slyk/types';

/**
 * Export `getImageSources`.
 */

export const getImageSources = (
  filename: string,
  extension: string,
  retinaSuffix: string = '@2x'
): ImageSources => ({
  defaultUrl: require(`payspace/client/assets/images/${filename}.${extension}`),
  retinaUrl: require(`payspace/client/assets/images/${filename}${retinaSuffix}.${extension}`)
});
