// @flow

/**
 * Module dependencies.
 */

import * as linkify from 'linkifyjs';
import { BackgroundImage } from 'react-components/media';
import {
  Button,
  Heading,
  ModalPortal,
  typography,
  useImageSources
} from '@slyk/design-system';

import { Fill } from 'react-components/layout';
import { type Product, payspaceSignupModes } from '@slyk/types';
import { color, media } from 'react-components/styles';
import { getImageSources } from 'payspace/client/core/utils/image';
import { ifProp, prop } from 'styled-tools';
import { isNil } from 'lodash';
import { useIntersection } from 'payspace/client/hooks/use-intersection';
import { usePayspace } from '@slyk/payspace-context';
import { useTranslate } from '@slyk/i18n';
import BackgroundPreview from 'payspace/client/components/home/header/mobile-app-preview/background-preview';
import Container from 'payspace/client/components/home/container';
import DesktopAction from './desktop-action';
import GetAppModal from 'payspace/client/components/home/get-app-modal';
import ProductsPreview from 'payspace/client/components/home/header/mobile-app-preview/products-preview';
import React, { type Node, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import iphoneImage from 'payspace/client/assets/images/mobile-app-preview/iphone.webp';
import remarkGfm from 'remark-gfm';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  canDownload: boolean,
  code: ?string,
  downloadAppUrl: string,
  onGetApp: () => void,
  products: Array<{|
    ...Product,
    displayPrice: string
  |}>
|};

/**
 * `HeaderWrapper` styled component.
 */

const HeaderWrapper = styled.header`
  margin-bottom: 56px;
  position: relative;

  ${media.min('xs')`
    margin-bottom: 70px;
  `}

  ${media.min('lg')`
    margin-bottom: 100px;
    min-height: 795px;
  `}
`;

/**
 * `DesktopBackgroundImage` styled component.
 */

const DesktopBackgroundImage = styled(BackgroundImage)`
  display: none;

  ${media.min('lg')`
    background-position: center bottom !important;
    bottom: -10% !important;
    display: block;
  `}

  ${media.min('xxl')`
    bottom: -20% !important;
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  --navbar-height: ${prop('paddingTop', 122)}px;

  display: grid;
  grid-gap: 56px 16px;
  grid-template-columns: repeat(6, 1fr);
  padding-top: var(--navbar-height);

  ${ifProp('isPrivate', css`
    padding-top: calc(var(--navbar-height) + 8px);
  `)}

  ${media.min('ms')`
    grid-gap: 70px 32px;
    grid-template-columns: repeat(8, 1fr);
    padding-top: 186px;
  `}

  ${media.min('lg')`
    grid-row-gap: 0;
    grid-template-columns: repeat(12, 1fr);
    padding-top: 115px;
  `}

  ${media.min('xl')`
    margin: 0 -32px;
  `}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  grid-column: 1 / -1;
  max-width: 478px;
  position: relative;

  ${media.min('ms')`
    grid-column: 2 / -2;
    margin: 0 auto;
  `}

  ${media.min('lg')`
    grid-column: 2 / span 5;
    margin: 0;
  `}
`;

/**
 * `SmallBackgroundWrapper` styled component.
 */

const SmallBackgroundWrapper = styled.div`
  --top: calc(${prop('top', 122)}px * -1);

  bottom: 0;
  left: -16px;
  max-height: 522px;
  position: absolute;
  right: -16px;
  top: var(--top);
  z-index: -1;

  ${ifProp('isPrivate', css`
    top: calc(var(--top) - 8);
  `)}

  ${media.min('xs')`
    display: none;
  `}
`;

/**
 * `SmallBackgroundImage` styled component.
 */

const SmallBackgroundImage = styled(BackgroundImage)`
  background-position: top center;
`;

/**
 * `LinearGradient` styled component.
 */

const LinearGradient = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 19.1%, #ffffff 89.07%);
  bottom: 0%;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Heading).attrs({ as: 'h1', level: 'h2' })`
  color: ${color('primary')};
  font-weight: 600;

  ${ifProp('hasGreeting', css`
    margin-bottom: 24px;
  `, css`
    margin-bottom: 48px;
  `)}

  ${media.min('ms')`
    ${ifProp('hasGreeting', css`
      margin-bottom: 16px;
    `)}
  `}

  ${media.min('lg')`
    padding-top: 18%;
  `}
`;

/**
 * `Greeting` styled component.
 */

const Greeting = styled(ReactMarkdown)`
  ${typography.styles.textNormal}

  color: ${color('secondary')};
  margin-bottom: 40px;
  position: relative;
  word-break: break-word;

  ${media.min('md')`
    grid-column: 1 / -1;
    margin-bottom: 56px;
  `}

  p {
    margin: 0;
  }

  ul,
  ol {
    list-style: revert;
    margin: revert;
    padding: revert;
    text-align: start;
  }
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  ${media.min('xs')`
    width: max-content;
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-column: 1 / -1;
  position: relative;

  ${media.min('xs')`
    margin: 0 -16px;
    padding-bottom: 76px;
    padding-top: 75px;
  `}

  ${media.min('ms')`
    margin: 0 -24px;
  `}

  ${media.min('lg')`
    grid-column: 8 / span 4;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
  `}
`;

/**
 * `MobileBackgroundImage` styled component.
 */

const MobileBackgroundImage = styled(BackgroundImage)`
  display: none;

  ${media.min('xs')`
    display: block;
  `}

  ${media.min('lg')`
    display: none;
  `}
`;

/**
 * `FrameWrapper` styled component.
 */

const FrameWrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  max-height: 680px;
  max-width: 256px;
  position: relative;
  transition: transform 1s ease-in-out;

  ${ifProp('isIntersecting', css`
    transform: scale(1);
  `, css`
    transform: scale(0.95);
  `)}

  ${media.min('xs')`
    transform: none;
  `}

  ${media.min('ms')`
    max-width: 324px;
  `}

  ${media.min('lg')`
    margin-right: 0;
  `}
`;

/**
 * `IPhoneImage` styled component.
 */

const IPhoneImage = styled.img`
  display: block;
  position: relative;
  z-index: 1;
`;

/**
 * Background style.
 */

const backgroundStyle = css`
  backdrop-filter: opacity(0);
  bottom: 14%;
  display: none;
  filter: blur(45px);
  height: 100%;
  left: 38%;
  max-height: 284px;
  max-width: 138px;
  opacity: 0.5;
  right: initial;
  top: initial;
  transform: rotate(18.35deg);
  width: 100%;

  ${media.min('xs')`
    display: block;
  `}

  ${media.min('ms')`
    bottom: 10%;
    left: 45%;
    max-height: 556px;
    max-width: 271px;
  `}
`;

/**
 * `StyledBackgroundImage` styled component.
 */

const StyledBackgroundImage = styled(BackgroundImage)`
  ${backgroundStyle}
`;

/**
 * `DefaultBackground` styled component.
 */

const DefaultBackground = styled(Fill)`
  ${backgroundStyle}

  background: linear-gradient(180deg, rgba(11, 102, 185, 0.8) 0%, rgba(12, 63, 134, 0.32) 0.01%, rgba(22, 80, 168, 0.648) 100%);
`;

/**
 * `Header` component.
 */

function Header(props: Props): Node {
  const {
    canDownload,
    code,
    downloadAppUrl,
    onGetApp,
    products
  } = props;

  const [isModalOpen, setModalOpen] = useState(false);
  const {
    background,
    greeting,
    logo: payspaceLogo,
    name,
    signupMode,
    title
  } = usePayspace();

  const { isIntersecting, ref } = useIntersection();
  const isPrivate = signupMode === payspaceSignupModes.private;
  const { translate } = useTranslate();
  const payspaceTitle = title || name;
  const isVideo = background?.type === 'video';
  const hasBackgroundVideo = isVideo && background?.status === 'completed';
  const backgroundUrl = useImageSources(isVideo ? background?.shareUrl : background?.url, '1024x');
  const formattedGreeting = useMemo(() => {
    if (!greeting) {
      return greeting;
    }

    const linkableList = linkify.find(greeting) ?? [];
    const urlLinks = linkableList.filter(({ type }) => type === 'url');

    return urlLinks.reduce((result, { value }) => {
      const formattedValue = value.replace('\\_', '_');

      return result.replace(value, formattedValue);
    }, greeting);
  }, [greeting]);

  const [navbarHeight, setNavbarHeight] = useState<number>(0);

  useEffect(() => {
    const elem = document.getElementsByTagName('nav')[0];

    if (elem) {
      setNavbarHeight(elem.clientHeight);
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      const elem = document.getElementsByTagName('nav')[0];

      if (elem) {
        setNavbarHeight(elem.clientHeight);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <HeaderWrapper>
        <DesktopBackgroundImage {...getImageSources('home/background', 'webp')} />

        <Container>
          <ContentWrapper
            isPrivate={isPrivate}
            paddingTop={navbarHeight}
          >
            <TextWrapper>
              <SmallBackgroundWrapper
                isPrivate={isPrivate}
                top={navbarHeight}
              >
                <SmallBackgroundImage {...getImageSources('home/small-background', 'webp')} />

                <LinearGradient />
              </SmallBackgroundWrapper>

              {payspaceTitle && (
                <Title hasGreeting={Boolean(greeting)}>
                  {payspaceTitle}
                </Title>
              )}

              {formattedGreeting && (
                <Greeting remarkPlugins={[remarkGfm]}>
                  {formattedGreeting}
                </Greeting>
              )}

              {!canDownload && (
                <DesktopAction
                  code={code}
                  openModal={() => setModalOpen(true)}
                />
              )}

              {canDownload && downloadAppUrl && (
                <StyledButton
                  href={downloadAppUrl}
                  rel={'noopener'}
                  responsive={false}
                  size={'large'}
                  target={'_blank'}
                >
                  {translate('header.appDownload')}
                </StyledButton>
              )}
            </TextWrapper>

            <ImageWrapper>
              <MobileBackgroundImage {...getImageSources('home/background-mobile', 'webp')} />

              <FrameWrapper
                isIntersecting={isIntersecting}
                ref={ref}
              >
                <IPhoneImage
                  alt={'IPhone'}
                  height={'100%'}
                  src={iphoneImage}
                  width={'100%'}
                />

                {!isNil(background) ? <StyledBackgroundImage {...backgroundUrl} /> : <DefaultBackground />}

                {hasBackgroundVideo ? (
                  <BackgroundPreview
                    background={background}
                    description={title}
                    logoUrl={payspaceLogo?.url}
                    name={name}
                    onGetApp={onGetApp}
                  />
                ) : (
                  <ProductsPreview
                    description={title}
                    logoUrl={payspaceLogo?.url}
                    name={name}
                    products={products}
                  />
                )}
              </FrameWrapper>
            </ImageWrapper>
          </ContentWrapper>
        </Container>
      </HeaderWrapper>

      {!canDownload && (
        <ModalPortal isOpen={isModalOpen}>
          <GetAppModal
            code={code}
            downloadAppUrl={downloadAppUrl}
            isVisible={isModalOpen}
            onRequestClose={() => setModalOpen(false)}
            showOnlyQrCode
          />
        </ModalPortal>
      )}
    </>
  );
}

/**
 * Export `Header` component.
 */

export default Header;
