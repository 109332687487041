// @flow

/**
 * Module dependencies.
 */

import { Button, Heading, Text } from '@slyk/design-system';
import { Col, Container, Row } from 'react-components/layout';
import type { ErrorProps } from '@slyk/error-boundaries';
import { color } from 'react-components/styles';
import { isProduction } from '@slyk/core';
import React, { type Node } from 'react';
import sentryClient from '@slyk/sentry-client';
import styled from 'styled-components';

/**
 * `Details` styled component.
 */

const Details = styled.details`
  white-space: pre-wrap;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

/**
 * `DefaultErrorMessage` component.
 */

function DefaultErrorMessage(errorProps: ErrorProps): Node {
  const { error, errorInfo, sentryEventId } = errorProps;

  return (
    <Container>
      <Row>
        <Col>
          <Heading
            as={'h1'}
            color={color('primary')}
            fontWeight={600}
            marginBottom={'16px'}
            paddingTop={'32px'}
          >
            {'Something bad happened'}
          </Heading>

          {!isProduction() && (
            <>
              <Text marginBottom={'8px'}>
                {error && error.toString()}
              </Text>

              <Details>
                <summary>
                  {'Stack trace'}
                </summary>

                {error?.stack}
              </Details>

              <Details>
                <summary>
                  {'Component stack'}
                </summary>

                {errorInfo?.componentStack}
              </Details>
            </>
          )}

          {sentryClient.isReportDialogToShow() && (
            <Button
              onClick={() => sentryClient.showReportDialog({ sentryEventId })}
              size={'medium'}
            >
              {'Report feedback'}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}

/**
 * Export `DefaultErrorMessage` component.
 */

export default DefaultErrorMessage;
