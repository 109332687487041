// @flow

/**
 * Export `dimensions`.
 */

export const dimensions = {
  home: {
    navbar: {
      height: 118
    }
  }
};
