// @flow

/**
 * Export `satCode`.
 */

export const satCode = 'sat';

/**
 * Export `satSymbol`.
 */

export const satSymbol = 'sats';
