// @flow

/**
 * Module dependencies.
 */

import { Button, Caption } from '@slyk/design-system';
import { Fill } from 'react-components/layout';
import { Icon } from 'react-components/media';
import { type Media } from '@slyk/types';
import { color, media } from 'react-components/styles';
import { ifProp, theme } from 'styled-tools';
import React, { type Node, useState } from 'react';
import logoPlaceholder from 'payspace/client/assets/images/mobile-app-preview/logo-placeholder.webp';
import soundOffIcon from 'payspace/client/assets/svg/32/sound-off.svg';
import soundOnIcon from 'payspace/client/assets/svg/32/sound-on.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  background: ?Media,
  description: ?string,
  logoUrl: ?string,
  name: string,
  onGetApp: () => void
|};

/**
 * `StyledFill` styled component.
 */

const StyledFill = styled(Fill)`
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;

  ${media.min('ms')`
    bottom: 8px;
    left: 8px;
    right: 8px;
    top: 8px;
  `}
`;

/**
 * `Video` styled component.
 */

const Video = styled.video`
  border-radius: 40px;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

/**
 * `SoundControlButton` styled component.
 */

const SoundControlButton = styled.button`
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: ${ifProp('isVisible', 1, 0)};
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity ${theme('animations.defaultTransition')};
  width: 100%;
  z-index: 2;
`;

/**
 * `MaskWrapper` styled component.
 */

const MaskWrapper = styled.div`
  background-color: ${color.transparentize('black', 0.1)};
  border-radius: 68px;
  height: 100px;
  position: absolute;
  width: 100px;

  ${media.min('xs')`
    height: 144px;
    width: 144px;
  `}
`;

/**
 * `IconWrapper` styled component.
 */

const IconWrapper = styled.div`
  align-items: center;
  background-color: ${color.transparentize('black', 0.5)};
  border-radius: 68px;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;

  ${media.min('xs')`
    height: 68px;
    width: 68px;
  `}
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  position: absolute;
`;

/**
 * `Svg` styled component.
 */

const Svg = styled.svg`
  bottom: 4px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 3;

  ${media.min('ms')`
    bottom: 8px;
  `}
`;

/**
 * Text style.
 */

const textStyle = css`
  color: ${color('white')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * `Name` styled component.
 */

const Name = styled(Caption).attrs({ as: 'div', size: 'small' })`
  ${textStyle}

  width: 232px;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Caption).attrs({ as: 'div', size: 'extraSmall' })`
  ${textStyle}

  width: 268px;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  pointer-events: auto;
  position: initial;
`;

/**
 * `BackgroundPreview` component.
 */

function BackgroundPreview(props: Props): Node {
  const { background, description, logoUrl, name, onGetApp } = props;
  const fileUrl = background?.url;
  const [isSoundControlVisible, setSoundControlVisible] = useState(false);
  const [isMuted, setMuted] = useState(true);

  return (
    <>
      <StyledFill
        onMouseLeave={() => setSoundControlVisible(false)}
        onMouseOver={() => setSoundControlVisible(true)}
      >
        <Video
          autoPlay
          loop
          muted={isMuted}
          playsInline
        >
          <source src={fileUrl} />
        </Video>

        <SoundControlButton
          aria-label={isMuted ? 'Sound On' : 'Sound Off'}
          isVisible={isSoundControlVisible}
          onClick={() => setMuted(!isMuted)}
        >
          <MaskWrapper />

          <IconWrapper>
            <StyledIcon
              aria-hidden
              color={'white'}
              icon={isMuted ? soundOffIcon : soundOnIcon}
              size={'32px'}
            />
          </IconWrapper>
        </SoundControlButton>
      </StyledFill>

      <Svg
        fill={'none'}
        viewBox={'0 0 306 271'}
      >
        <defs>
          <linearGradient
            gradientUnits={'userSpaceOnUse'}
            id={'linear_gradient_1'}
            x1={'152.612'}
            x2={'152.612'}
            y1={'0'}
            y2={'172.911'}
          >
            <stop stopOpacity={'0'} />

            <stop
              offset={'1'}
              stopOpacity={'0.3'}
            />
          </linearGradient>
        </defs>

        <path
          clipRule={'evenodd'}
          d={'M305.225 0V233.149C305.225 253.677 288.476 270.318 267.816 270.318H37.4092C16.7487 270.318 0 253.677 0 233.149V0H305.225Z'}
          fill={'url(#linear_gradient_1)'}
          fillRule={'evenodd'}
        />

        <foreignObject
          height={'20'}
          width={'268'}
          x={'19'}
          y={'168'}
        >
          <Description>
            {description}
          </Description>
        </foreignObject>

        <foreignObject
          height={'20'}
          width={'232'}
          x={'55'}
          y={'138'}
        >
          <Name>
            {name}
          </Name>
        </foreignObject>

        <path
          d={'M42.4434 138.943C38.7774 133.684 24.9855 133.687 21.324 138.943C20.1451 140.633 19.5557 143.538 19.5557 147.659C19.5557 151.778 20.1451 154.684 21.324 156.374C24.8682 161.457 38.8059 161.797 42.4434 156.582C43.6208 154.891 44.2117 151.918 44.2117 147.659C44.2117 143.538 43.6208 140.633 42.4434 138.943Z'}
          fill={'white'}
          stroke={'white'}
          strokeWidth={'2'}
        />

        <mask
          height={'26'}
          id={'mask0_1157_53100'}
          maskUnits={'userSpaceOnUse'}
          width={'26'}
          x={'19'}
          y={'135'}
        >
          <path
            d={'M42.4434 138.943C38.7774 133.684 24.9855 133.687 21.324 138.943C20.1451 140.633 19.5557 143.538 19.5557 147.659C19.5557 151.778 20.1451 154.684 21.324 156.374C24.8682 161.457 38.8059 161.797 42.4434 156.582C43.6208 154.891 44.2117 151.918 44.2117 147.659C44.2117 143.538 43.6208 140.633 42.4434 138.943Z'}
            fill={'white'}
          />
        </mask>

        <image
          height={'27.8766'}
          href={logoUrl ?? logoPlaceholder}
          mask={'url(#mask0_1157_53100)'}
          preserveAspectRatio={'xMidYMid slice'}
          width={'28.0569'}
          x={'17.8564'}
          xlinkHref={logoUrl ?? logoPlaceholder}
          y={'133.311'}
        />

        <foreignObject
          height={'40'}
          rx={'4'}
          width={'267.781'}
          x={'19'}
          y={'199'}
        >
          <StyledButton
            fullWidth
            onClick={onGetApp}
            size={'medium'}
          >
            {'Get started'}
          </StyledButton>
        </foreignObject>
      </Svg>
    </>
  );
}

/**
 * Export `BackgroundPreview` component.
 */

export default BackgroundPreview;
