// @flow

/**
 * Module dependencies.
 */

import { formatCurrency as defaultFormatCurrency } from '@slyk/assets';
import { satCode, satSymbol } from 'payspace/client/core/constants/assets';
import { upperCase } from 'lodash';
import BigNumber from 'bignumber.js';

/**
 * Sat rate.
 */

const satRate = 100000000;

/**
 * Export `formatCurrency` util.
 */

export function formatCurrency(value: number | string | BigNumber, options: Object) {
  const { assetCode } = options;
  const amount = new BigNumber(value);

  if (assetCode !== 'btc' || amount.isEqualTo(0) || amount.abs().isGreaterThanOrEqualTo(0.1)) {
    return defaultFormatCurrency(value, options);
  }

  return defaultFormatCurrency(amount.multipliedBy(satRate).toFixed(), {
    ...options,
    assetCode: satCode
  }).replace(upperCase(satCode), satSymbol);
}
