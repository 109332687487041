// @flow

/**
 * Module dependencies.
 */

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from '@apollo/client';

import { RestLink } from 'apollo-link-rest';
import { isProduction } from '@slyk/core';
import { resolve } from 'url';
import config from 'config';

/**
 * Slyk api base url.
 */

const slykApiBaseUrl = config.get('api.slyk.baseUrl');

/**
 * Possible types.
 */

const possibleTypes = {
  MediaItem: ['Image', 'Video'],
  UploadItem: ['UploadImage', 'UploadVideo']
};

/**
 * Http link.
 */

const httpLink = new HttpLink({
  uri: resolve(slykApiBaseUrl, '/app/graphql')
});

/**
 * Rest link.
 */

const restLink = new RestLink({
  responseTransformer: response => {
    // The `response` here is null when the server responds with 404.
    if (!response) {
      return Promise.reject({ result: { code: 'not_found', status: 404 } });
    }

    if (response.status === 204 || !response.json) {
      return null;
    }

    return response.json().then(({ data }) => data);
  },
  uri: config.get('api.slyk.baseUrl')
});

/**
 * Default options.
 */

const defaultOptions = {
  mutate: {
    errorPolicy: 'all'
  },
  query: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  },
  watchQuery: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  }
};

/**
 * Export `createApolloClient`.
 */

export function createApolloClient() {
  const link = ApolloLink.from([restLink, httpLink]);
  const cache = new InMemoryCache({ possibleTypes });
  const client = new ApolloClient<any>({
    cache,
    connectToDevTools: !isProduction(),
    defaultOptions,
    link
  });

  return client;
}
