// @flow

/**
 * Module dependencies.
 */

import { BackgroundImage } from 'react-components/media';
import { ConditionalRoute } from '@slyk/routing';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getImageSources } from 'payspace/client/core/utils/image';
import { payspaceStatuses } from '@slyk/types';
import { usePayspace } from '@slyk/payspace-context';
import { useTranslate } from '@slyk/i18n';
import ConfirmEmail from 'payspace/client/containers/confirm-email';
import Home from 'payspace/client/containers/home';
import PageLayout from 'payspace/client/components/layouts/page-layout';
import PayspaceNotActive from 'payspace/client/containers/payspace-not-active';
import React, { type Node, useEffect, useMemo } from 'react';
import ResetPassword from 'payspace/client/containers/reset-password';
import ResetPasswordSuccess from 'payspace/client/containers/reset-password-success';
import config from 'config';
import dashboardRoutes from '@slyk/config/dashboard-routes';
import moment from 'moment';
import routes from '@slyk/config/payspace-routes';
import styled from 'styled-components';

/**
 * Slyk link.
 */

const slykLink = config.get('externalLinks.slykWebsite');

/**
 * `StyledBackgroundImage` styled component.
 */

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: contain;
`;

/**
 * `App` container.
 */

function App(): Node {
  const { i18n, translate } = useTranslate();
  const payspace = usePayspace();
  const domainIsReady = payspace.status === payspaceStatuses.ready;
  const domainIsUnconfigured = payspace.status === payspaceStatuses.unconfigured;
  const htmlAttributes = useMemo(() => ({
    lang: i18n.language
  }), [i18n.language]);

  const title = translate('metatags.payspace.title', {
    payspaceName: payspace.name
  });

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <Helmet
        defaultTitle={title}
        htmlAttributes={htmlAttributes}
        titleTemplate={`%s - ${title}`}
      />

      <Switch>
        <ConditionalRoute
          exact
          path={routes.home}
          rules={[{
            external: true,
            redirectTo: dashboardRoutes.signIn,
            rule: domainIsUnconfigured
          }, {
            redirectTo: routes.payspaceNotActive,
            rule: !domainIsReady && !domainIsUnconfigured
          }]}
        >
          <Home />
        </ConditionalRoute>

        <ConditionalRoute
          exact
          path={routes.acceptInvitation}
          rules={[{
            redirectTo: routes.payspaceNotActive,
            rule: !domainIsReady
          }]}
        >
          <Redirect to={routes.home} />
        </ConditionalRoute>

        <Route
          path={[
            routes.resetPassword,
            routes.resetPasswordSuccess,
            routes.confirmEmail,
            routes.payspaceNotActive
          ]}
        >
          <PageLayout
            header={(
              <Switch>
                <Route
                  exact
                  path={routes.confirmEmail}
                >
                  <BackgroundImage {...getImageSources('background', 'webp')} />

                  <StyledBackgroundImage {...getImageSources('confirm-email', 'webp')} />
                </Route>

                <Route
                  exact
                  path={routes.payspaceNotActive}
                >
                  <BackgroundImage {...getImageSources('background', 'webp')} />

                  <StyledBackgroundImage {...getImageSources('payspace-not-active', 'webp')} />
                </Route>

                <Route path={'*'}>
                  <BackgroundImage {...getImageSources('auth-image-fallback', 'webp')} />
                </Route>
              </Switch>
            )}
            logoLink={slykLink}
          >
            <Switch>
              <ConditionalRoute
                exact
                path={routes.resetPassword}
                rules={[{
                  redirectTo: routes.payspaceNotActive,
                  rule: !domainIsReady
                }]}
              >
                <ResetPassword />
              </ConditionalRoute>

              <ConditionalRoute
                exact
                path={routes.resetPasswordSuccess}
                rules={[{
                  redirectTo: routes.payspaceNotActive,
                  rule: !domainIsReady
                }]}
              >
                <ResetPasswordSuccess />
              </ConditionalRoute>

              <ConditionalRoute
                exact
                path={routes.confirmEmail}
                rules={[{
                  redirectTo: routes.payspaceNotActive,
                  rule: !domainIsReady
                }]}
              >
                <ConfirmEmail />
              </ConditionalRoute>

              <ConditionalRoute
                exact
                path={routes.payspaceNotActive}
                rules={[{
                  redirectTo: routes.home,
                  rule: domainIsReady
                }]}
              >
                <PayspaceNotActive />
              </ConditionalRoute>
            </Switch>
          </PageLayout>
        </Route>

        <Redirect to={routes.home} />
      </Switch>
    </>
  );
}

/**
 * Export `App` container.
 */

export default App;
