// @flow

/**
 * Module dependecies.
 */

import { Container as BaseContainer } from 'react-components/layout';
import { media } from 'react-components/styles';
import { theme } from 'styled-tools';
import styled from 'styled-components';

/**
 * `Container` styled component.
 */

const Container = styled(BaseContainer)`
  max-width: 1192px;
  padding-left: 16px;
  padding-right: 16px;

  ${media.min('ms')`
    padding-left: 24px;
    padding-right: 24px;
  `}

  ${media.min('lg')`
    padding-left: 32px;
    padding-right: 32px;
  `}

  ${media.min('xxl')`
    max-width: ${theme('grid.containers.lg')}px;
  `}
`;

/**
 * Export `Container` component.
 */

export default Container;
