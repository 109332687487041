// @flow

/**
 * Module dependencies.
 */

import { Button, IconButton, Text, closeIcon16 } from '@slyk/design-system';
import { color, media } from 'react-components/styles';
import { theme } from 'styled-tools';
import { useTranslate } from '@slyk/i18n';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  goToDashboard: string,
  onDismiss: () => void
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('info100')};
  min-height: 64px;
  padding: 12px 16px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${theme('zIndex.banner')};
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-items: center;
  column-gap: 24px;
  display: flex;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ size: 'small' })`
  color: ${color('secondary')};
  display: none;

  ${media.min('xs')`
    display: block;
  `}
`;

/**
 * `GoToDashboardButton` styled component.
 */

const GoToDashboardButton = styled(Button)`
  width: max-content;
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
`;

/**
 * `Banner` component.
 */

function Banner({ goToDashboard, onDismiss }: Props): Node {
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Description>
            {translate('banner.description')}
          </Description>

          <GoToDashboardButton
            href={goToDashboard}
            responsive={false}
            size={'medium'}
          >
            {translate('common.actions.goToDashboard')}
          </GoToDashboardButton>
        </Content>

        <StyledIconButton
          aria-label={translate('common.actions.close')}
          colorTheme={'transparent'}
          icon={closeIcon16}
          iconSize={'small'}
          onClick={onDismiss}
          size={'16px'}
        />
      </ContentWrapper>
    </Wrapper>
  );
}

/**
 * Export `Banner` component.
 */

export default Banner;
