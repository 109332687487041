// @flow

/**
 * Module dependencies.
 */

import { get, has } from 'lodash';
import { parseObject } from '@slyk/core';

/**
 * Export from global variable `_config` which contains
 * the config injected by the koa server.
 */

const config = parseObject(window._config); // eslint-disable-line no-underscore-dangle

/**
 * Remove global reference.
 */

delete window._config; // eslint-disable-line no-underscore-dangle

/**
 * Export `config`.
 */

export default {
  ...config,
  get: (key: string) => get(config, key),
  has: (key: string) => has(config, key)
};
