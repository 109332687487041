// @flow

/**
 * Module dependencies.
 */

import { UrlResolver } from '@slyk/url-resolver';
import config from 'config';

/**
 * Resolver.
 */

const resolver = new UrlResolver({
  domain: config.get('app.domain'),
  protocol: window.location.protocol
});

/**
 * Export `payspaceUrlResolver`.
 */

export function payspaceUrlResolver(domainSlug: string, route: string) {
  try {
    return resolver.resolve(domainSlug, route);
  } catch (error) {
    throw new Error('You must provide a valid route');
  }
}

/**
 * Export `getAppDownloadUrl`.
 */

export function getAppDownloadUrl(payspaceSlug: string, code: ?string): string {
  if (code) {
    return resolver.resolve(payspaceSlug, `/r/${code}`);
  }

  return resolver.resolve(payspaceSlug, '/get');
}
