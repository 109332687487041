// @flow

/**
 * Module dependencies.
 */

import { ApolloProvider } from '@apollo/client';
import { BrowserProvider } from '@slyk/browser';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider, withSSR as withI18nSSR } from 'react-i18next';
import { createApolloClient } from 'payspace/client/graphql/apollo-client';
import { i18n } from 'payspace/client/core/localization/i18n';
import { initializeAnalytics } from '@slyk/analytics';
import { isProduction, parseObject } from '@slyk/core';
import { render } from 'react-dom';
import React from 'react';
import Root from 'payspace/client/containers/root';
import config from 'config';
import packageJson from 'package.json';
import sentryClient from '@slyk/sentry-client';

/**
 * Base path.
 */

const basePath = config.get('basePath');

/**
 * Initial localization data.
 */

const { i18nStore, language } = parseObject(window._initialLocalizationData) || {}; // eslint-disable-line no-underscore-dangle

/**
 * Remove global references.
 */

delete window._initialLocalizationData; // eslint-disable-line no-underscore-dangle

/**
 * Initialize apollo client.
 */

const apolloClient = createApolloClient();

/**
 * Initialize analytics.
 */

const { AnalyticsTracker } = initializeAnalytics({
  gaOptions: {
    debug: !isProduction()
  },
  trackers: [{
    gaOptions: { name: 'client' },
    trackingId: config.get('analytics.clientTrackingId')
  }, {
    gaOptions: { name: 'seegno' },
    trackingId: config.get('analytics.seegnoTrackingId')
  }].filter(tracker => tracker.trackingId)
});

/**
 * Root with i18n SSR.
 */

const RootWithI18nSSR = withI18nSSR()(Root);

/**
 * Sentry configurations.
 */

const { dsn, extras, init, reportDialog, tags } = config.get('sentry');

/**
 * Sentry client initialization.
 */

sentryClient.init({
  dsn: dsn?.client,
  options: { extras, init, tags },
  packageJson,
  reportDialog
});

/**
 * Render.
 */

render((
  <BrowserProvider userAgent={window.navigator.userAgent}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter basename={basePath}>
        <AnalyticsTracker />

        <ApolloProvider client={apolloClient}>
          <RootWithI18nSSR
            initialI18nStore={i18nStore}
            initialLanguage={language}
          />
        </ApolloProvider>
      </BrowserRouter>
    </I18nextProvider>
  </BrowserProvider>
  // $FlowFixMe
), document.getElementById('root'));
