// @flow

/**
 * Module dependencies.
 */

import {
  Card,
  Heading,
  IconButton,
  ModalBackdrop,
  type ModalProps,
  Text,
  closeIcon24
} from '@slyk/design-system';

import { Fill } from 'react-components/layout';
import { color, media } from 'react-components/styles';
import { ifProp, theme } from 'styled-tools';
import { usePayspace } from '@slyk/payspace-context';
import { useTranslate } from '@slyk/i18n';
import PhoneNumberInputForm from './phone-number-input-form';
import QRCode from 'react-qr-code';
import React, { type Node, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  ...ModalProps,
  closeOnPressBackdrop?: boolean,
  code: ?string,
  downloadAppUrl: string,
  showOnlyQrCode?: boolean
|};

/**
 * `StyledModalBackdrop` styled component.
 */

const StyledModalBackdrop = styled(ModalBackdrop)`
  background-color: ${color('white')};
`;

/**
 * `ModalContent` styled component.
 */

const ModalContent = styled(Fill)`
  background-color: transparent;
  display: flex;
  justify-content: center;
  outline: none;
  overflow: auto;
  position: fixed;
  transition: visibility ${theme('animations.quartTransition')};
  z-index: ${theme('zIndex.modal.container')};

  ${ifProp('isVisible', css`
    animation: ${theme('keyframes.modalFadeInUp')} ${theme('animations.quartTransition')} both;
    visibility: visible;
  `, css`
    animation: ${theme('keyframes.fadeOut')} ${theme('animations.defaultTransition')} both;
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `ModalBody` styled component.
 */

const ModalBody = styled.div`
  background-color: ${color('white')};
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: minmax(auto, 564px);
  margin: 0 auto auto;
  padding: 16px 16px 40px;

  ${media.min('md')`
    padding: 32px 16px 56px;
  `}
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)`
  margin-left: auto;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Heading).attrs({ as: 'h1' })`
  color: ${color('primary')};
  font-weight: 600;
  margin-top: 16px;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ size: 'small' })`
  color: ${color('gray500')};
  margin-bottom: 24px;

  ${media.min('md')`
    margin-bottom: 40px;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

/**
 * `Circle` styled component.
 */

const Circle = styled(Text)`
  align-items: center;
  border: 1px solid ${color('gray500')};
  border-radius: 26px;
  display: flex;
  height: 36px;
  justify-content: center;
  margin: auto;
  text-transform: uppercase;
  width: 36px;
`;

/**
 * `QRCodeWrapper` styled component.
 */

const QRCodeWrapper = styled.div`
  background-color: ${color('gray200')};
  border-radius: ${theme('dimensions.borderRadius')}px;
  margin: 0 auto;
  max-width: 212px;
  padding: 16px;
`;

/**
 * `GetAppModal` component.
 */

function GetAppModal(props: Props): Node {
  const {
    closeOnPressBackdrop,
    code,
    downloadAppUrl,
    isVisible,
    onRequestClose,
    showOnlyQrCode
  } = props;

  const { name } = usePayspace();
  const { translate } = useTranslate();
  const modalRef = useRef();

  useEffect(() => {
    if (isVisible && modalRef.current) {
      // $FlowFixMe
      const timeout = setTimeout(() => modalRef.current.focus(), 500);

      return () => clearTimeout(timeout);
    }
  }, [isVisible]);

  useEffect(() => {
    const documentRoot = document.getElementById('root');

    if (!documentRoot) {
      return;
    }

    if (isVisible) {
      documentRoot.setAttribute('aria-hidden', 'true');
    } else {
      documentRoot.removeAttribute('aria-hidden');
    }
  }, [isVisible]);

  return (
    <>
      <StyledModalBackdrop
        isClickable={closeOnPressBackdrop}
        isVisible={isVisible}
        onRequestClose={onRequestClose}
      />

      <ModalContent
        aria-hidden={!isVisible}
        aria-modal
        isVisible={isVisible}
        ref={modalRef}
        role={'dialog'}
        tabIndex={isVisible ? -1 : undefined}
      >
        <ModalBody>
          <StyledIconButton
            aria-label={translate('common.actions.close')}
            colorTheme={'transparent'}
            icon={closeIcon24}
            iconSize={'24px'}
            onClick={onRequestClose}
            size={'24px'}
          />

          <Title>
            {translate('modal.title', { name })}
          </Title>

          <Description>
            {translate('modal.lead')}
          </Description>

          <Content>
            {!showOnlyQrCode && (
              <>
                <Card>
                  <Text
                    fontWeight={700}
                    marginBottom={'24px'}
                    marginBottomMd={'40px'}
                  >
                    {translate('modal.byPhone')}
                  </Text>

                  <PhoneNumberInputForm
                    code={code}
                    onSend={() => onRequestClose()}
                  />
                </Card>

                <Circle>
                  {translate('modal.or')}
                </Circle>
              </>
            )}

            {downloadAppUrl && (
              <Card>
                <Text
                  fontWeight={700}
                  marginBottom={'24px'}
                  marginBottomMd={'40px'}
                >
                  {translate('qrCode.description')}
                </Text>

                <QRCodeWrapper>
                  <QRCode
                    size={180}
                    value={downloadAppUrl}
                  />
                </QRCodeWrapper>
              </Card>
            )}
          </Content>
        </ModalBody>
      </ModalContent>
    </>
  );
}

/**
 * Export `GetAppModal` component.
 */

export default GetAppModal;
