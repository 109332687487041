// @flow

/**
 * Module dependencies.
 */

import {
  FieldError,
  Form,
  SubmitButton,
  caretIcon24,
  useSnackbar
} from '@slyk/design-system';

import { color } from 'react-components/styles';
import { getNetworkErrorTranslationPaths } from '@slyk/core';
import { gql, useMutation } from '@apollo/client';
import { theme } from 'styled-tools';
import { useField } from '@seegno/react-forms';
import { usePayspace } from '@slyk/payspace-context';
import { useRecaptcha } from '@slyk/recaptcha';
import { useTranslate } from '@slyk/i18n';
import PhoneNumberInput from 'react-phone-input-2';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback } from 'react';
import config from 'config';
import phoneNumberStyle from 'react-phone-input-2/lib/high-res.css';
import slykEndpoints from '@slyk/config/slyk-endpoints';
import styled from 'styled-components';

/**
 * Excluded countries.
 */

const excludedCountries = ['cu', 'ir', 'kp', 'sy', 'ss'];

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  code: ?string,
  onSend?: () => void
|};

/**
 * `PhoneInputProps` type.
 */

type PhoneInputFieldProps = {|
  disabled: boolean
|};

/**
 * Json schema.
 */

const jsonSchema = {
  properties: {
    countryCode: { type: 'string' },
    phoneNumber: { type: 'string' }
  },
  required: ['phoneNumber'],
  type: 'object'
};

/**
 * Send message mutation
 */

const sendMessageMutation = gql`
  mutation SendMessage($values: JSONObject!) {
    sendMessage(input: $values) @rest(
      method: "POST"
      path: "${slykEndpoints.joinPayspaceBySms}"
    ) {
      NoResponse
    }
  }
`;

/**
 * Initial form values.
 */

const initialFormValues = {
  phoneNumber: '+1'
};

/**
 * `FormGroup` styled component.
 */

const FormGroup = styled.div`
  background-color: ${color('gray300')};
  border-radius: ${theme('dimensions.borderRadius')}px;
  display: grid;
  grid-row-gap: 0;
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content;
  margin-bottom: 16px;

  ${phoneNumberStyle}

  .react-tel-input {
    .form-control {
      background: transparent;
      border: none;
      font-size: 16px;
      outline: none;
      padding-left: 72px;
      padding-right: 16px;
      width: 100%;

      &:focus {
        outline: 0;
      }
    }

    .selected-flag {
      padding-left: 16px;
      width: 52px;

      .arrow {
        background: url('data:image/svg+xml;utf8,${caretIcon24}');
        border: none;
        height: 16px;
        transform: translate(-3px, -4px);
        width: 16px;

        &.up {
          border: none;
          transform: translate(-3px, -4px) rotate(180deg);
        }
      }

      &:hover,
      &:focus {
        background: transparent;
      }
    }

    .flag-dropdown {
      background-color: ${color('gray300')};
      border: none;

      &.open,
      &.open .selected-flag,
      &:hover,
      &:focus {
        background: transparent;
        border-radius: 0;
      }
    }

    .country-list {
      border-radius: ${theme('dimensions.borderRadius')}px;
      box-shadow: ${theme('boxShadow.dropdownMenu')};

      .flag {
        left: 13px;
        margin-top: -3px;
        top: 8px;
      }

      .divider {
        border-color: ${color('gray200')};
      }

      .country {
        outline: 0;
        padding-bottom: 8px;
        padding-top: 8px;
        position: relative;

        .dial-code {
          color: ${color('secondary')};
        }

        &.highlight,
        &:hover {
          background-color: ${color('blue100')};
        }
      }
    }
  }
`;

/**
 * `FlexCenter` styled component.
 */

const FlexCenter = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1;
  grid-row: 1;
  justify-content: center;
  position: relative;
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)`
  grid-column: 2;
  grid-row: 1;
  margin: 9px 8px 9px 0;
`;

/**
 * `RecaptchaWrapper` styled component.
 */

const RecaptchaWrapper = styled.div`
  position: relative;
  z-index: ${theme('zIndex.recaptcha')};
`;

/**
 * `PhoneInputField` component.
 */

function PhoneInputField({ disabled }: PhoneInputFieldProps): Node {
  const name = 'phoneNumber';
  const { translate } = useTranslate();
  const { error, meta: { touched }, onChange, ...fieldProps } = useField(name);
  const { onChange: onCountryCodeChange, ...countryCodeFieldProps } = useField('countryCode');
  const handleOnChange = useCallback((value, { countryCode }) => {
    onChange(value);
    onCountryCodeChange(countryCode);
  }, [onChange, onCountryCodeChange]);

  return (
    <>
      <PhoneNumberInput
        {...fieldProps}
        country={'us'}
        countryCodeEditable={false}
        disabled={disabled}
        excludeCountries={excludedCountries}
        inputProps={{ name }}
        onChange={handleOnChange}
        placeholder={translate('header.phoneNumber')}
        preferredCountries={['us']}
        specialLabel={null}
      />

      <input
        {...countryCodeFieldProps}
        onChange={null}
        type={'hidden'}
      />

      <FieldError
        error={error}
        name={name}
        touched={touched}
      />
    </>
  );
}

/**
 * `PhoneNumberInputForm` component.
 */

function PhoneNumberInputForm(props: Props): Node {
  const { className, code, onSend } = props;
  const { slug } = usePayspace();
  const { translate } = useTranslate();
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const [sendMessage, { loading }] = useMutation(sendMessageMutation, {
    context: {
      headers: {
        'x-payspace-slug': slug
      }
    },
    onCompleted: () => {
      showSuccessMessage(translate('phoneNumberForm.successMessage'), {
        hasIcon: true
      });
    },
    onError: error => {
      const errorCode = error?.networkError?.result?.code;

      showErrorMessage((
        <RawHtml>
          {translate(getNetworkErrorTranslationPaths(errorCode, 'phoneNumberForm.errors'))}
        </RawHtml>
      ), { hasIcon: true });

      throw error;
    }
  });

  const {
    isRecaptchaExecuting,
    renderRecaptcha,
    submitWithRecaptcha
  } = useRecaptcha({
    onSubmit: ({ reset, ...rest }) => {
      sendMessage({
        variables: {
          values: { ...rest }
        }
      }).then(() => {
        if (onSend) {
          onSend();
        }

        reset();
      });
    },
    recaptchaSiteKey: config.get('recaptcha.siteKey')
  });

  const isLoading = loading || isRecaptchaExecuting;
  const handleSubmit = useCallback(({ countryCode, phoneNumber }, { reset }) => {
    const values = {
      code,
      countryCode,
      phoneNumber: `+${phoneNumber}`,
      reset
    };

    return submitWithRecaptcha(values);
  }, [code, submitWithRecaptcha]);

  return (
    <Form
      className={className}
      initialValues={initialFormValues}
      jsonSchema={jsonSchema}
      onSubmit={handleSubmit}
    >
      <FormGroup>
        <FlexCenter>
          <PhoneInputField disabled={isLoading} />
        </FlexCenter>

        <StyledSubmitButton
          isSubmitting={isLoading}
          responsive={false}
          size={'medium'}
        >
          {translate('phoneNumberForm.submit')}
        </StyledSubmitButton>
      </FormGroup>

      <RecaptchaWrapper>
        {renderRecaptcha()}
      </RecaptchaWrapper>
    </Form>
  );
}

/**
 * Export `PhoneNumberInputForm` component.
 */

export default PhoneNumberInputForm;
