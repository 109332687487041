// @flow

/**
 * Module dependencies.
 */

import { Heading, Loading, ModalPortal } from '@slyk/design-system';
import { Helmet } from 'react-helmet';
import { color, media } from 'react-components/styles';
import { find, isEmpty, isNil, map, omitBy, reduce } from 'lodash';
import { formatCurrency } from 'payspace/client/core/utils/formatter';
import { getAppDownloadUrl } from 'payspace/client/core/utils/url-resolver';
import { gql, useQuery } from '@apollo/client';
import { payspaceSignupModes } from '@slyk/types';
import { sortedSocialNetworks } from '@slyk/social-networks';
import { useBrowser } from '@slyk/browser';
import { usePayspace } from '@slyk/payspace-context';
import { useQueryString } from 'payspace/client/hooks/use-query-string';
import { useTranslate } from '@slyk/i18n';
import Banner from 'payspace/client/components/home/banner';
import BigNumber from 'bignumber.js';
import Connect from 'payspace/client/components/home/connect';
import Container from 'payspace/client/components/home/container';
import EarnRewards from 'payspace/client/components/home/earn-rewards';
import Footer from 'payspace/client/components/home/footer';
import GetAppModal from 'payspace/client/components/home/get-app-modal';
import Header from 'payspace/client/components/home/header';
import Highlights from 'payspace/client/components/home/highlights';
import Navbar from 'payspace/client/components/home/navbar';
import React, {
  type Node,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import TaskList from 'payspace/client/components/home/earn-rewards/task-list';
import cookies from 'browser-cookies';
import routes from '@slyk/config/dashboard-routes';
import styled from 'styled-components';

/**
 * Social networks.
 */

const socialNetworks = [
  ...sortedSocialNetworks,
  'supportEmail'
];

/**
 * Home query.
 */

const homeQuery = gql`
  query Home {
    defaultCurrency {
      assetCode: code
      decimalPlaces
      decimalPlacesToDisplay
      skipTrailingZeros
      symbol
    }

    socialNetworks: settings(
      filter: [{
        name: "code",
        value: "in:${socialNetworks.join()}"
      }]
    ) {
      data {
        code
        value
      }
    }

    products(
      filter: [{
        name: "featured",
        value: "true"
      }]
      page: {
        number: 1,
        size: 4
      }
      sort: "order"
    ) {
      data {
        id
        name
        location
        price
        thumbnail {
          ... on Image {
            status
            type
            url
          }
          ... on Video {
            status
            thumbnailUrl
            type
            url
          }
        }
        unitOfAccount
      }
      total
    }

    referralProgram: referralProgramSettings {
      data {
        code
        value
      }
    }

    rewardAsset: defaultBonusAsset {
      assetCode: code
      baseRates {
        quoteAssetCode
        rate
      }
      decimalPlaces
      decimalPlacesToDisplay
      skipTrailingZeros
      symbol
      type
    }

    tasks(
      all: true
      filter: [{
        name: "enabled",
        value: "true"
      }]
      sort: "order"
    ) {
      data {
        amount
        id
        media {
          ... on Image {
            status
            type
            uploadId
            url
          }
          ... on Video {
            duration
            playbackId
            status
            thumbnailUrl
            type
            uploadId
            url
          }
        }
        name
      }
    }
  }
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Loading)`
  display: flex;
  justify-content: center;
  margin: 16px 0;
`;

/**
 * `SectionsWrapper` styled component.
 */

const SectionsWrapper = styled.div`
  margin: 0 16px;

  ${media.min('ms')`
    margin: 0 24px;
  `}

  ${media.min('lg')`
    margin: 0 32px;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Heading).attrs({ as: 'h2', level: 'h3' })`
  color: ${color('primary')};
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-gap: 32px;
  max-width: 1192px;
  padding: 56px 0 80px;

  &::after,
  &::before {
    display: none;
  }

  ${media.min('md')`
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  `}
`;

/**
 * Is facebook.
 */

function isFacebook(userAgent: string) {
  return userAgent.includes('FB_IAB/FB4A') || userAgent.includes('FBAN/FBIOS');
}

/**
 * Get setting value.
 */

function getSettingValue(settings: Array<Object>, code: string): ?string {
  return find(settings, { code })?.value;
}

/**
 * `Home` container.
 */

function Home(): Node {
  const [isBannerVisible, setBannerVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { i18n, translate } = useTranslate();
  const { name, signupMode, slug } = usePayspace();
  const browser = useBrowser();
  const closeModal = useCallback(() => setModalOpen(false), []);
  const { code } = useQueryString() ?? {};
  const downloadAppUrl = getAppDownloadUrl(slug, code);
  const canDownload = browser.some(['mobile', 'tablet']) && !isFacebook(browser.getUA());
  const isPublicPayspace = signupMode === payspaceSignupModes.public;
  const getSlykApp = useCallback(() => {
    if (!canDownload) {
      setModalOpen(true);

      return;
    }

    window.open(downloadAppUrl, '_blank');
  }, [downloadAppUrl, canDownload]);

  const { data, loading } = useQuery(homeQuery, {
    context: {
      headers: {
        'x-payspace-slug': slug
      }
    },
    skip: !isPublicPayspace
  });

  const { products, tasks } = useMemo(() => {
    const locale = i18n.language;
    const tasks = map(data?.tasks?.data, ({ amount, ...rest }) => ({
      ...rest,
      amount,
      normalizedAmount: formatCurrency(amount, { ...data?.rewardAsset, locale })
    }));

    const products = map(data?.products?.data, ({ price, ...rest }) => {
      const currency = data?.defaultCurrency;
      const displayValue = formatCurrency(price, { ...currency, locale });
      const isFree = new BigNumber(price).isZero();

      return {
        ...rest,
        displayPrice: isFree ? translate('products.free') : displayValue,
        price
      };
    });

    return {
      products,
      tasks
    };
  }, [data, i18n.language, translate]);

  const [initialTask, ...taskList] = tasks;
  const normalizeReferralProgram = useMemo(() => {
    const settings = data?.referralProgram?.data;

    return omitBy({
      referralEarnEnabled: getSettingValue(settings, 'referralEarnBonusEnabled'),
      referralEarnPercentage: getSettingValue(settings, 'referralEarnBonusPercentage'),
      referralEnabled: getSettingValue(settings, 'referralPurchaseBonusEnabled'),
      referralLimit: getSettingValue(settings, 'referralPurchaseBonusLimit'),
      referralNetworkEnabled: getSettingValue(settings, 'referralNetworkPurchaseBonusEnabled'),
      referralPercentage: getSettingValue(settings, 'referralPurchaseBonusPercentage')
    }, isNil);
  }, [data]);

  const socialNetworks = useMemo(() => {
    return reduce(data?.socialNetworks?.data, (result, { code, value }) => {
      if (isNil(value)) {
        return result;
      }

      if (code === 'supportEmail') {
        return {
          ...result,
          email: value
        };
      }

      return {
        ...result,
        [code]: value
      };
    }, {});
  }, [data]);

  const handleDismiss = useCallback(() => {
    setBannerVisible(false);
  }, []);

  useEffect(() => {
    if (isNil(cookies.get('sessionToken'))) {
      return setBannerVisible(false);
    }

    return setBannerVisible(true);
  }, []);

  return (
    <>
      <Helmet titleTemplate={'%s'}>
        <title>
          {translate('title', { name })}
        </title>
      </Helmet>

      {isBannerVisible && (
        <Banner
          goToDashboard={routes.overview}
          onDismiss={handleDismiss}
        />
      )}

      <Navbar onGetApp={getSlykApp} />

      <Header
        canDownload={canDownload}
        code={code}
        downloadAppUrl={downloadAppUrl}
        onGetApp={getSlykApp}
        products={products}
      />

      {signupMode === payspaceSignupModes.public && (
        <>
          {loading ? (
            <StyledLoading
              active
              relative
            />
          ) : (
            <>
              {(!isEmpty(products) || data?.rewardAsset) && (
                <SectionsWrapper>
                  <Title>
                    {translate('earnRewards.title')}
                  </Title>

                  <StyledContainer>
                    {data?.rewardAsset && (
                      <EarnRewards
                        cardDirection={isEmpty(products) ? 'row' : 'column'}
                        name={name}
                        onGoToGetAccess={getSlykApp}
                        referralProgram={normalizeReferralProgram}
                        rewardAsset={data.rewardAsset}
                        task={initialTask}
                      />
                    )}

                    {!isEmpty(products) && (
                      <Highlights
                        isRightAligned={data?.rewardAsset && normalizeReferralProgram.referralEnabled}
                        onGoToGetAccess={getSlykApp}
                        products={products}
                      />
                    )}
                  </StyledContainer>
                </SectionsWrapper>
              )}

              {taskList.length !== 0 && (
                <TaskList
                  name={name}
                  onGoToGetAccess={getSlykApp}
                  referralEnabled={normalizeReferralProgram.referralEnabled}
                  rewardAsset={data.rewardAsset}
                  tasks={taskList}
                />
              )}

              {!isEmpty(socialNetworks) && (
                <Connect socialNetworks={socialNetworks} />
              )}
            </>
          )}
        </>
      )}

      <Footer
        getSlykApp={getSlykApp}
        payspaceName={name}
      />

      {!canDownload && (
        <ModalPortal isOpen={isModalOpen}>
          <GetAppModal
            code={code}
            downloadAppUrl={downloadAppUrl}
            isVisible={isModalOpen}
            onRequestClose={closeModal}
          />
        </ModalPortal>
      )}
    </>
  );
}

/**
 * Export `Home` container.
 */

export default Home;
