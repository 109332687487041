// @flow

/**
 * Module dependencies.
 */

import { useEffect, useRef, useState } from 'react';

/**
 * Export `useIntersection` hook.
 */

export function useIntersection() {
  const ref = useRef<any>(null);
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIntersecting(true);
      } else {
        setIntersecting(false);
      }
    }, {});

    if (ref.current) {
      observer.observe(element);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return {
    isIntersecting,
    ref
  };
}
