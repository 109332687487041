// @flow

/**
 * Module dependencies.
 */

import { Helmet } from 'react-helmet';
import { ThemeConsumer } from 'styled-components';
import { color } from 'react-components/styles';
import React, { type Node } from 'react';

/**
 * Export `Favicons` component.
 */

export function Favicons(): Node {
  return (
    <ThemeConsumer>
      {theme => (
        <Helmet>
          <link
            href={require('./assets/favicons/android-chrome-192x192.png')}
            rel={'icon'}
            sizes={'192x192'}
            type={'image/png'}
          />

          <link
            href={require('./assets/favicons/android-chrome-512x512.png')}
            rel={'icon'}
            sizes={'512x512'}
            type={'image/png'}
          />

          <link
            href={require('./assets/favicons/apple-touch-icon.png')}
            rel={'apple-touch-icon'}
            sizes={'180x180'}
          />

          <link
            href={require('./assets/favicons/apple-touch-icon-57x57.png')}
            rel={'apple-touch-icon'}
            sizes={'57x57'}
          />

          <link
            href={require('./assets/favicons/apple-touch-icon-60x60.png')}
            rel={'apple-touch-icon'}
            sizes={'60x60'}
          />

          <link
            href={require('./assets/favicons/apple-touch-icon-72x72.png')}
            rel={'apple-touch-icon'}
            sizes={'72x72'}
          />

          <link
            href={require('./assets/favicons/apple-touch-icon-76x76.png')}
            rel={'apple-touch-icon'}
            sizes={'76x76'}
          />

          <link
            href={require('./assets/favicons/apple-touch-icon-114x114.png')}
            rel={'apple-touch-icon'}
            sizes={'114x114'}
          />

          <link
            href={require('./assets/favicons/apple-touch-icon-120x120.png')}
            rel={'apple-touch-icon'}
            sizes={'120x120'}
          />

          <link
            href={require('./assets/favicons/apple-touch-icon-144x144.png')}
            rel={'apple-touch-icon'}
            sizes={'144x144'}
          />

          <link
            href={require('./assets/favicons/apple-touch-icon-152x152.png')}
            rel={'apple-touch-icon'}
            sizes={'152x152'}
          />

          <link
            href={require('./assets/favicons/apple-touch-icon-180x180.png')}
            rel={'apple-touch-icon'}
            sizes={'180x180'}
          />

          <link
            href={require('./assets/favicons/favicon-16x16.png')}
            rel={'icon'}
            sizes={'16x16'}
            type={'image/png'}
          />

          <link
            href={require('./assets/favicons/favicon-32x32.png')}
            rel={'icon'}
            sizes={'32x32'}
            type={'image/png'}
          />

          <link
            href={require('./assets/favicons/mstile-150x150.png')}
            rel={'icon'}
            sizes={'150x150'}
            type={'image/png'}
          />

          <link
          // $FlowFixMe
            href={require('./assets/favicons/favicon.ico')}
            rel={'shortcut icon'}
          />

          <link
            content={color('primary')({ theme })}
            href={require('./assets/favicons/safari-pinned-tab.svg')}
            rel={'mask-icon'}
          />
        </Helmet>
      )}
    </ThemeConsumer>
  );
}
