// @flow

/**
 * Module dependencies.
 */

import { Link } from '@slyk/design-system';
import React, { type Node } from 'react';

/**
 * `Props` type.
 */

type Props = {|
  children?: Node,
  className?: string,
  colorTheme?: string,
  email: string,
  hasRel?: boolean,
  openInNewTab?: boolean,
  rel?: string
|};

/**
 * Export `MailTo` component.
 */

export function MailTo(props: Props): Node {
  const {
    children,
    email,
    hasRel = true,
    openInNewTab = true,
    rel = 'noopener',
    ...rest
  } = props;

  return (
    <Link
      {...rest}
      {...hasRel ? { rel } : {}}
      href={`mailto:${email}`}
      target={openInNewTab ? '_blank' : '_self'}
    >
      {children}
    </Link>
  );
}
