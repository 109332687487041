// @flow

/**
 * Module dependencies.
 */

import { Button, Heading, MaskedIcon, Text } from '@slyk/design-system';
import { Helmet } from 'react-helmet';
import { MainContent } from 'payspace/client/components/layouts/page-layout';
import { color } from 'react-components/styles';
import { useTranslate } from '@slyk/i18n';
import React, { type Node } from 'react';
import emailValidatedSvg from 'payspace/client/assets/svg/140/email-validated.svg';
import routes from '@slyk/config/payspace-routes';
import styled from 'styled-components';

/**
 * `Content` styled component.
 */

const Content = styled.div` 
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

/**
 * `StyledMaskedIcon` styled component.
 */

const StyledMaskedIcon = styled(MaskedIcon)`
  align-self: center;
  margin-bottom: 24px;
`;

/**
 * `ResetPasswordSuccess` container.
 */

function ResetPasswordSuccess(): Node {
  const { translate } = useTranslate();
  const title = translate('resetPasswordSuccess.title');

  return (
    <>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>

      <MainContent>
        <Content>
          <Heading
            as={'h1'}
            color={color('primary')}
            marginBottom={'24px'}
            marginBottomMd={'40px'}
          >
            {title}
          </Heading>

          <StyledMaskedIcon
            color={color('primary')}
            icon={emailValidatedSvg}
          />

          <Text
            color={color('gray500')}
            marginBottom={'24px'}
          >
            {translate('resetPasswordSuccess.description')}
          </Text>

          <Button
            size={'large'}
            to={routes.home}
          >
            {translate('resetPasswordSuccess.successButton')}
          </Button>
        </Content>
      </MainContent>
    </>
  );
}

/**
 * Export `ResetPasswordSuccess` container.
 */

export default ResetPasswordSuccess;
